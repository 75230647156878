/* Hero Section Styling */
.hero-section {
  position: relative;
  height: 75vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: url(''); /* Fallback for browsers */
}
.insights {
  margin: 1rem 0; /* Adds some spacing */
  padding: 1rem;
  background-color: #f9f9f9; /* Light background for better visibility */
  border-radius: 8px; /* Rounded corners */
  text-align: center;
}

.insights h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.sponsor-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Centers the image */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .insights h2 {
    font-size: 1.5rem;
  }

  .sponsor-image {
    width: 80%; /* Adjust image size for smaller screens */
  }
}

@media (max-width: 480px) {
  .insights h2 {
    font-size: 1.2rem;
  }

  .sponsor-image {
    width: 90%; /* Further adjust for very small screens */
  }
}


.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.hero-overlay {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  font-size: 1.5rem;
  line-height: 1.8;
}

/* Intro Section */
.Intro-us {
  padding: 50px 20px;
  background-color: #f3f3f3;
  color: #444;
  text-align: center;
}

.Intro-us h2 {
  font-size: 1.8rem;
  margin: 10px 0;
  color: #007bff;
}

.Intro-us p {
  font-size: 1rem;
  color: #666;
}

/* Carousel Section Styling */
.carousel-section {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 800px; /* Reduced height for the carousel */
}

.carousel {
  display: flex;
  animation: scrollCarousel 60s linear infinite;
}

.carousel-image {
  width: 40%;
  /* object-fit: ; */
  height: 30%;
}

@keyframes scrollCarousel {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


/* About Us Section Styling */
.about-us {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
}

.about-us h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #007bff;
}

.about-us p {
  font-size: 1rem;
  margin-bottom: 40px;
  color: #444;
}

/* About Us Cards - 2 cards per row */
.about-us-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 cards per row by default */
  gap: 20px;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease-in-out;
}

.about-us-cards.animate-cards {
  opacity: 1;
  transform: translateY(0);
}

.about-us-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-us-card:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

.about-us-card img {
  width: 50px; /* Reduced width for smaller images in About Us cards */
  height: auto;
  border-radius: 8px;
}

.about-us-card h3 {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #007bff;
}

.about-us-card p {
  font-size: 1rem;
  color: #555;
}

/* Responsive Design for Small Screens (Tablets, Mobiles) */
@media (max-width: 768px) {
  /* About Us Cards: 2 cards per row on smaller screens */
  .carousel-image{
    height: 100px;
  }
  .about-us-cards {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }

  /* Intro Section */
  .Intro-us h2 {
    font-size: 1.5rem;
  }

  .Intro-us p {
    font-size: 0.9rem;
  }

  /* Carousel Section */
  .carousel {
    animation: scrollCarousel 15s linear infinite;
  }
}

@media (max-width: 480px) {
  /* About Us Cards: 1 card per row on very small screens */
  .about-us-cards {
    grid-template-columns: 1fr; /* 1 card per row */
  }

  /* Intro Section */
  .Intro-us h2 {
    font-size: 1.2rem;
  }

  .Intro-us p {
    font-size: 0.8rem;
  }

  /* Carousel Section */
  .carousel {
    animation: scrollCarousel 12s linear infinite;
  }
}

/* Insight Card Styling */
.insight-card {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  width: 50%; /* Adjusted to take full width within the grid */
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.insight-card:hover {
  transform: translateY(-10px);
}

.insight-card h3 {
  font-size: 1.3rem;
  color: #333;
}

.insight-card p {
  font-size: 1rem;
  color: #666;
}

/* Insight Cards Container */
.insight-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row on large screens */
  gap: 20px;
  width :100px;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease-in-out;
}

.insight-cards-container.animate-cards {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  /* Insight Cards: 1 card per row on smaller screens */
  .insight-cards-container {
    grid-template-columns: 1fr; /* 1 card per row */
  }
  .insight-card {
    width: 90%;
  }
}