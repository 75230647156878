/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: 90px;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
}

.outer {
  padding: 40px 20px;
  perspective: 1000px; /* Enables 3D perspective */
  margin-top: 30px;
}

/* Outer Card for 3D Effect */
.outer-card {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
}

/* Card Container */
.card-container {
  width: 18rem;
  height: 24rem;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card-container:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3), 0 0 20px rgba(255, 255, 255, 0.6); /* Glowing Effect */
}

/* Add Lighting Effect */
.card-container::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent 60%);
  opacity: 0;
  transform: rotate(45deg);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.card-container:hover::before {
  opacity: 1;
  transform: rotate(0deg);
}

/* Event Image */
.event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.4s ease;
}

.card-container:hover .event-image {
  transform: scale(1.1);
}

/* Overlay */
.event-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateY(100%);
}

.card-container:hover .event-overlay {
  opacity: 1;
  transform: translateY(0);
}

/* Overlay Text */
.event-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.event-subtitle {
  font-size: 0.9rem;
}

/* 3D Mouse Interaction */
.outer-card:hover {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
}

.outer-card:hover .card-container {
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

/* Responsive Design */
@media (max-width: 992px) {
  .card-container {
    width: 26rem;
    height: 22rem;
  }
}

@media (max-width: 768px) {
  .card-container {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .outer {
    padding: 20px;
  }
  event-image{
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-right: 50PX;
  }
  .card-container {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  .event-overlay {
    padding: 10px;
  }
  .event-title {
    font-size: 1rem;
  }
  .event-subtitle {
    font-size: 0.8rem;
}

@media (max-width: 576px) {
  .outer {
    padding: 20px;
  }
  event-image{
    width: 100%;
    height: 100%;
    margin-right: 100px  ;
  }
}
}