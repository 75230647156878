.participant-form {
  max-width: 900px;
  margin: 50px auto;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  align-content: center;
}

.participant-form h2 {
  text-align: center;
  color: #0056b3;
  margin-bottom: 35px;
  font-weight: bold;
  font-size: 2rem;
}

.participant-form .form-group {
  margin-bottom: 20px;
}

.participant-form .form-group label {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.participant-form .form-control {
  font-size: 1rem;
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  transition: all 0.3s ease;
}

.participant-form .form-control:focus {
  border-color: #0056b3;
  box-shadow: 0 0 8px rgba(0, 86, 179, 0.5);
}

.participant-form .btn {
  font-size: 1.1rem;
  padding: 12px 25px;
  border-radius: 8px;
  width: 100%;
  transition: all 0.3s ease;
}

.participant-form .btn-primary {
  background-color: #0056b3;
  border: none;
}

.participant-form .btn-primary:hover {
  background-color: #003d82;
}

.participant-form .btn-success {
  background-color: #28a745;
  border: none;
}

.participant-form .btn-success:hover {
  background-color: #218838;
}

.participant-form .qr-code-section {
  text-align: center;
  margin-top: 30px;
}

.participant-form .qr-code-section img {
  width: 350px;
  height: 650px;
  margin-bottom: 15px;
}

.participant-form .qr-code-section p {
  font-size: 1rem;
  font-weight: 600;

}

.participant-form .total-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #495057;
  margin-top: 20px;
}

.participant-form h5 {
  margin-top: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #495057;
}

.participant-form .form-group .form-control {
  margin-bottom: 15px;
}

.participant-form .form-group input[type="file"] {
  padding: 0;
}

.participant-form .btn:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .participant-form {
    padding: 20px;
    margin-top: 90px;
  }

  .participant-form .btn {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .participant-form .form-control {
    font-size: 1rem;
    padding: 10px;
  }
  .participant-form .qr-code-section img {
    width: 175px;
    height: 550px;
    margin-bottom: 15px;
  }

}
