/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
}

.outer {
  padding: 40px 20px;
}

/* Card Container */
.card-container {
  width: 18rem;
  height: 24rem;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card-container:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

/* Seminar Image */
.card-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.4s ease;
}

.card-container:hover img {
  transform: scale(1.1);
}

/* Overlay */
.seminar-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateY(100%);
}

.card-container:hover .seminar-overlay {
  opacity: 1;
  transform: translateY(0);
}

/* Overlay Text */
.seminar-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.seminar-subtitle {
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 992px) {
  .card-container {
    width: 16rem;
    height: 22rem;
  }
}

@media (max-width: 768px) {
  .card-container {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .outer {
    padding: 20px;
  }
}
