/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: 100px;
}

.event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  min-height: 100vh;
  background-color: #fff;
  margin-top: 100px;
}
.event-image{
  width: auto;
  height: 50%;
  border-radius: 5%;
  margin-bottom: 20px;
}
/* Event Card Styling */
.event-info {
  width: 100%;
  max-width: 950px;
  background: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin-bottom: 40px;
}

.event-info:hover {
  transform: translateY(-10px);
}

.event-info h2 {
  font-size: 2.5rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 15px;
}

.event-info h3 {
  font-size: 1.8rem;
  color: #009688;
  font-weight: 500;
  margin-bottom: 25px;
}

.event-info p {
  font-size: 1.1rem;
  color: #777;
  line-height: 1.6;
  text-align: justify;
}

/* Action Card Styling */
.action-card {
  width: 100%;
  max-width: 380px;
  background-color: #009688;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  margin-top: 40px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.action-card:hover {
  transform: translateY(-10px);
  background-color: #00796b;
}

.action-card h4 {
  color: #ffffff;
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.action-card p {
  color: #ffffff;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.register-btn {
  width: 100%;
  padding: 12px;
  font-size: 1.2rem;
  background-color: #ff5722;  /* Warm orange */
  border: none;
  border-radius: 25px;
  color: #fff;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
}

.register-btn:hover {
  background-color: #e64a19;
}

.rulebook-btn {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  background-color: #ffffff;
  border: 2px solid #00796b;
  color: #00796b;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.rulebook-btn:hover {
  background-color: #f1f1f1;
}

/* Modal Styles */
.modal-content {
  border-radius: 10px;
  padding: 20px;
}

.modal-header {
  background-color: #009688;
  color: white;
  border-radius: 10px;
  padding: 20px;
}

.modal-body {
  display: flex;
  justify-content: center;
}

iframe {
  width: 100%;
  height: 80vh;
  border: none;
  border-radius: 10px;
}
.event-image{
  width: 50%;
  height: 50%;
  border-radius: 5%;
}
/* Responsive Design */
@media (max-width: 768px) {
  .event-info {
    padding: 20px;
  }

  .event-info h2 {
    font-size: 2rem;
  }

  .action-card {
    padding: 20px;
  }

  .register-btn {
    font-size: 1rem;
    padding: 10px;
  }

  .rulebook-btn {
    font-size: 1rem;
    padding: 10px;
  }
  .event-image{
    width: 80%;
    height: 20%;
    border-radius: 5%;
  }
}
