* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.footer {
  background-color: #000000;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: start;
}

.contact-info {
  font-size: 16px;
}

.contact-info h4 {
  margin-bottom: 10px;
}

.contact-info ul {
  list-style-type: none;
}

.contact-info li {
  margin: 5px 0;
}

.social-icons {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.social-icon {
  color: #fff;
  text-decoration: none;
  margin: 5px 0;
  font-size: 18px;
}

.social-icon:hover {
  color: #ddd;
}

@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .social-icons {
    text-align: center;
    margin-top: 10px;
  }

  .social-icon {
    margin: 5px 0;
  }

  .contact-info {
    font-size: 14px;
  }
}
